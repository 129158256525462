import React, { FunctionComponent } from "react";

import "./Header.scss";

export const Header: FunctionComponent<{}> = () => (
    <div className="header">
        <div className="header__ribbon">
            <div className="header__ribbon__title">
                <div className="header__ribbon__title__subtitle">
                    Horácký folklorní soubor
                </div>
                <div className="header__ribbon__title__title">
                    BAJDYŠ
                </div>
            </div>
        </div>
    </div>
);
