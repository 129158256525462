import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { menuItems } from "./constants";
import { MenuItem } from "../MenuItem";
import "./Menu.scss";

export const Menu: FunctionComponent<{}> = () => (
    <div className="menu">
        {menuItems.map(({ title, link }) => (
            <MenuItem
                key={title}
                {...{ link, title }}
            />
        ))}
        <Link to="https://www.instagram.com/fs_bajdys/" style={{ height: 35 }}>
            <img
                src="/img/instagram.png"
                className="instagram_logo"
            />
        </Link>
    </div>
);
