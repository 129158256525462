import React, { FunctionComponent } from "react";

import "./styles.scss";

export const Home: FunctionComponent<{}> = () => (
    <>
        <h1>Horácký folklorní soubor Bajdyš</h1>
        <p>
            Horácký folklorní soubor Bajdyš vznikl v&nbsp;Třebíči v&nbsp;roce 1984.
            Své členy si vychovává od útlého věku v&nbsp;dětském souboru Bajdyšek
            pod záštitou Základní umělecké školy v&nbsp;Třebíči.
        </p>
        <div className="home-photo" />
    </>
);
