export const galleryItems = [
    {
        title: "Videa na YouTube",
        description: "Záznamy z několika našich vystoupení",
        link: "https://www.youtube.com/user/bajdystrebic",
    },
    {
        title: "Taneční představení ZUŠ Třebíč",
        description: "2017",
        link: "https://photos.app.goo.gl/vWWZZ9mzYtjXDDNP9",
    },
    {
        title: "Chorvatsko",
        description: "2017",
        link: "https://photos.app.goo.gl/654tjDMgfkeA2mwN6",
    },
    {
        title: "Bítešské hody",
        description: "2017",
        link: "https://photos.app.goo.gl/HwygwwkbjDcafDVW7",
    },
    {
        title: "Francie",
        description: "2015",
        link: "https://photos.app.goo.gl/QRK3KCfv3PoizxSo7",
    },
    {
        title: "Liptál",
        description: "2013",
        link: "https://photos.app.goo.gl/ncaFaiWVMGfiGL5w9",
    },
    {
        title: "Sardinie",
        description: "2013",
        link: "https://photos.app.goo.gl/8qcRuycrY1AoyKFG6",
    },
    {
        title: "Francie",
        description: "2012",
        link: "https://photos.app.goo.gl/BkQ9g9RhiUyX1uGPA",
    },
    {
        title: "Litva",
        description: "2011",
        link: "https://photos.app.goo.gl/7fBR4cNk1Xm5kctx9",
    },
    {
        title: "Itálie",
        description: "2010",
        link: "https://photos.app.goo.gl/GUdfP4y1PWycaaLy6",
    },
    {
        title: "Litva",
        description: "2009",
        link: "https://photos.app.goo.gl/qddvHVC2GPShk5MR8",
    },
    {
        title: "Švýcarsko a Holandsko",
        description: "2008",
        link: "https://photos.app.goo.gl/F6pQoF6pCJyV9shWA",
    },
    {
        title: "Dánsko",
        description: "2007",
        link: "https://photos.app.goo.gl/tTcm6PHUQXopVtVk8",
    },
    {
        title: "Další",
        link: "https://photos.app.goo.gl/dtDBhfuRdWB31xz96",
    },
];
