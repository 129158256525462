import React, { FunctionComponent } from "react";

import "./GalleryLink.scss";

export type GalleryLinkProps = {
    link: string;
    title: string;
    description?: string;
}

export const GalleryLink: FunctionComponent<GalleryLinkProps> = ({
    link,
    title,
    description,
}) => (
    <p className="gallery-link">
        <a href={link} target="_blank" rel="noopener noreferrer">
            {title}<br />
            {description ? (
                <span className="gallery-link__description">{description}</span>
            ) : null}
        </a>
    </p>
);
