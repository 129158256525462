import React, { FunctionComponent, Fragment } from "react";

import { GalleryLink } from "../components/GalleryLink";
import { galleryItems } from "./constants";

export const Gallery: FunctionComponent<{}> = () => (
    <Fragment>
        <h1>Galerie</h1>
        {galleryItems.map(({ title, link, description }) => (
            <GalleryLink
                key={title}
                {...{ title, link, description }}
            />
        ))}
    </Fragment>
);