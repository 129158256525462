import React, { FunctionComponent } from "react";

import "./Body.scss";

export type BodyProps = {
    children: any;
}

export const Body: FunctionComponent<BodyProps> = ({ children }) => (
    <div className="body">
        {children}
    </div>
);
