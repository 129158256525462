import React, { FunctionComponent } from "react";

import "./styles.scss";

export const Contact: FunctionComponent<{}> = () => (
    <>
        <h1>Kontakt</h1>

        <p>
            <div className="contact-name">Šárka Nohová</div>
            +420 732 962 638<br />
            mgrsarkanohova@gmail.com<br />
        </p>
    </>
);
