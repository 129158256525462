import React, { FunctionComponent } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Contact, Gallery, About, Home, Unknown } from "./screens";
import { Header, Menu, Body, ArrowLine, Footer } from "./components";

const Container: FunctionComponent = ({children}) => (
    <>
        <Header />
        <ArrowLine />
        <Menu />
        <Body>
            {children}
        </Body>
        <Footer />
    </>
);

const router = createBrowserRouter([
    { path: "/", element: <Container><Home /></Container> },
    { path: "/uvod", element: <Container><Home /></Container> },
    { path: "/o-nas", element: <Container><About /></Container> },
    { path: "/galerie", element: <Container><Gallery /></Container> },
    { path: "/kontakt", element: <Container><Contact /></Container> },
    { path: "*", element: <Container><Unknown /></Container> },
]);

export const Router: FunctionComponent = () => (
    <RouterProvider router={router} />
);
