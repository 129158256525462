import React, { FunctionComponent } from "react";

export const About: FunctionComponent<{}> = () => (
    <>
        <h1>O nás</h1>

        <p>
            Horácký folklorní soubor Bajdyš vznikl v&nbsp;Třebíči v&nbsp;roce 1984.
            V&nbsp;současné době působí pod záštitou Základní umělecké školy a&nbsp;své členy si
            vychovává od útlého věku v&nbsp;dětském souboru Bajdyšek.
        </p>
        <p>
            Proč právě Bajdyš?&nbsp;
            Je to jeden z&nbsp;charakteristických horáckých tanců, který svým humorným dějem a&nbsp;divokostí
            téměř přesně vystihuje duši mladých tanečníků a&nbsp;tanečnic. Z&nbsp;tanečníků
            a&nbsp;hudebníků vyzařuje mládí a&nbsp;radost, proto se soubor orientuje
            na pohybové ztvárnění folklóru a&nbsp;zpracovává různě žertovné motivy ze života
            (taneční pásma Žerotvné, Bajdyš, Hospoda, Horácké skočné, Hačau - polka, Vánoce na Horácku,
            mužské tance s&nbsp;košťaty), milostná témata (Láska a&nbsp;peníze) a&nbsp;rekrutské tance.
        </p><p>
            Většinu repertoáru tvoří tance z&nbsp;horáckého regionu, ale jsou zde zařazeny i&nbsp;tance
            z&nbsp;Valašska, Kopanic a&nbsp;Slovenska (Zemplín, Šariš, Myjava).
        </p>
    </>
);
