import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import "./MenuItem.scss";

export type MenuItemProps = {
    link: string;
    title: string;
}

export const MenuItem: FunctionComponent<MenuItemProps> = ({
    link,
    title,
}) => (
    <div className="menu-item">
        <Link to={link}>
            {title}
        </Link>
    </div>
);
