import React, { FunctionComponent } from "react";

import { Router } from "./Router";
import "./App.scss";

export const App: FunctionComponent<{}> = () => (
    <div className="app">
        <Router />
    </div>
);
